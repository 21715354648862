const agilityApi = (api) => {
  return {
    getSitemap : (items, langCode='en-ca') => {
      return Promise.resolve(
        api.getSitemapFlat({
          channelName: 'website',
          languageCode: langCode
        })
          .then(sitemap => sitemap)
          .catch(error => error.data)
      );
    },

    getSitemapNested : (langCode='en-ca') => {
      return Promise.resolve(
        api.getSitemapNested({
          channelName: 'website',
          languageCode: langCode
        })
        .then(sitemap => sitemap)
        .catch(error => error.data)
      )
    },

    getPage : (pageID, langCode='en-ca') => {
      return Promise.resolve(
        api.getPage({
          pageID: pageID,
          languageCode: langCode,
          contentLinkDepth: 6
        })
          .then(page => page)
          .catch(error => error.data)
      )
    },
    
    getContentItem : (contentID, langCode='en-ca') => {
      return Promise.resolve(
        api.getContentItem({
          contentID: contentID,
          languageCode: langCode,
          contentLinkDepth: 6
        })
          .then(contentItem => contentItem)
          .catch(error => error.data)
      )
    },
    
    getContentList : (referenceName, skip=0, take=50, langCode='en-ca') => {
      return Promise.resolve(
        api.getContentList({
          referenceName: referenceName,
          languageCode: langCode,
          take: take,
          skip: skip,
          sort: 'properties.created',
          direction: api.types.SortDirections.ASC
        })
          .then( contentList => contentList )
          .catch(error => error.data)
      );
    },

    getGallery : (galleryId) => {
      return Promise.resolve(
        api.getGallery({
          galleryID: galleryId
        })
        .then(gallery => gallery)
        .catch(error => error.data)
      )
    }
  }
}

module.exports = agilityApi;
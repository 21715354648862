/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const agility = require('@agility/content-fetch/dist/agility-content-fetch.browser');
const agilityApi = require('./src/helpers/api');

exports.onClientEntry = () => {
    const api = agility.getApi({
        guid: '5647e90c',
        apiKey: 'defaultlive.a9781f6ecfafc289e6d03c6b80e98ecb3a933218af894c64e7dfa6211279c168',
        isPreview: false
    });
    const requestUrl = window.location.href;

    const previewApi = agility.getApi({
        guid: '5647e90c',
        apiKey: 'defaultpreview.0872b63dd374675d78bb3af23428fdb4ffaf69969ea2a19b2711a683c3957426',
        isPreview: true
    });
    
    // window.__api = window.__api || api;
    window.__agilityProdApi = agilityApi(api);
    window.__agilityPreviewApi = agilityApi(previewApi);
    window.__agilityApi = window.location.href.indexOf('agilitypreviewkey') > -1 ? window.__agilityPreviewApi : window.__agilityProdApi;
    window.__languageCode = 'en-ca';
}